import React, { useState } from "react"
import { Dialog } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

const navigation = [
  {
    name: "Blog",
    href: "https://blog.wvm.dev/",
    color: "00E492",
    noopener: false,
  },
  { name: "Docs", href: "https://docs.wvm.dev/", color: "#fff" },
  { name: "GitHub", href: "https://github.com/weaveVM", color: "#fff" },
]

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-[#09060b]">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="https://wvm.dev" className="-m-1.5 p-1.5">
            <span className="sr-only">WeaveVM</span>
            <img
              className="h-8 w-auto"
              width={183}
              height={47}
              src="https://github.com/weaveVM/.github/blob/main/profile/Wordmark.png?raw=true"
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map(item => (
            <a
              key={item.name}
              href={item.href}
              target={item.noopener === false ? "_self" : "_blank"}
              rel={item.noopener === false ? undefined : "noopener noreferrer"}
              className="text-sm text-white font-semibold leading-6"
              style={{ color: item.color ? `#${item.color}` : "inherit" }}
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            href="https://docs.wvm.dev/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-semibold leading-6 text-primary bg-none border-2 border-primary/80 rounded-full px-3 py-1"
          >
            Read the docs <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black/60 backdrop-blur-xl px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="https://wvm.dev" className="-m-1.5 p-1.5">
              <span className="sr-only">WeaveVM</span>
              <img
                className="h-8 w-auto"
                width={180}
                height={47}
                src="/logo/Wordmark.png"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-300"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6 bg-red-500">
                {navigation.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    target={item.noopener === false ? "_self" : "_blank"}
                    rel={
                      item.noopener === false
                        ? undefined
                        : "noopener noreferrer"
                    }
                    className="-mx-3 block rounded-lg px-3 py-2 text-bas font-semibold leading-7 hover:bg-gray-900/20"
                    style={{ color: item.color ? `#${item.color}` : "inherit" }}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
